import React from 'react';
const images = {
  img1: require('../../images/saq_logo.jpg'),  
 
};

const Footer = () => {
  return (
    <div>
      <div className='bg-[#071F3A] text-white py-10 mt-16 font-semibold'>
        <div className='flex justify-around'>
          <div className='my-5 -mx-64 text-lg'>GET IN TOUCH</div>
          <div className='w-1/5'>
            <a href='https://saqtechnology.com/'>
              <span>
                <img className="h-16" src={images.img1} />
              </span>
            </a>
          </div>
        </div>
        <div className='flex justify-between mx-8 my-6'>
          <div>
            <div className='text-lg my-4'>ABOUT</div>
            <div className='leading-10 text-base font-normal'>
              <ul>
                <li className='hover:text-[#61CE70] hover:cursor-pointer'>Contact</li>
                <li className='hover:text-[#61CE70] hover:cursor-pointer'>Help Center</li>
                <li className='hover:text-[#61CE70] hover:cursor-pointer'>Refund</li>
                <li className='hover:text-[#61CE70] hover:cursor-pointer'>Conditions</li>
                <li className='hover:text-[#61CE70] hover:cursor-pointer'>Privacy Policy</li>
              </ul>
            </div>
          </div>
          <div>
            <div className='text-lg my-4'>CATEGORIES</div>
            <div className='leading-10 text-base font-normal'>
              <ul>
                <li className='hover:text-[#61CE70] hover:cursor-pointer'>Home</li>
                <li className='hover:text-[#61CE70] hover:cursor-pointer'>Explore</li>
                <li className='hover:text-[#61CE70] hover:cursor-pointer'>Tutorial</li>
                <li className='hover:text-[#61CE70] hover:cursor-pointer'>Practice</li>
                <li className='hover:text-[#61CE70] hover:cursor-pointer'>Pricing</li>
                <li className='hover:text-[#61CE70] hover:cursor-pointer'>Resource</li>
                <li className='hover:text-[#61CE70] hover:cursor-pointer'>Contact</li>
              </ul>
            </div>
          </div>
          <div>
            <div className='text-lg my-4'>COURSE</div>
            <div className='leading-10 text-base font-normal'>
              <ul>
                <li className='hover:text-[#61CE70] hover:cursor-pointer'>SalesForce</li>
                <li className='hover:text-[#61CE70] hover:cursor-pointer'>Full Stack Developer</li>
                <li className='hover:text-[#61CE70] hover:cursor-pointer'>Azure</li>
                <li className='hover:text-[#61CE70] hover:cursor-pointer'>Python</li>
                <li className='hover:text-[#61CE70] hover:cursor-pointer'>UI Path</li>
                <li className='hover:text-[#61CE70] hover:cursor-pointer'>SAP</li>
              </ul>
            </div>
          </div>
          <div>
            <div className='text-lg my-4'>Contact No</div>
            <div className='leading-10 text-base font-normal'>
              <div>+(91) 7093081073</div>
              <div>
              <p>
                <strong>Address:</strong> SAQ Technology,Machupalli road,<br/>Shankarapuram,Kadapa - 516001.
                <br/><strong>Landmark:</strong> Vignan Degree College
              </p>
              </div>
            </div>
            <div className='text-lg mt-12 mb-6'>TAKE YOUR LEARNING WITH YOU</div>
            <div className='leading-10 text-base font-normal'>
              <div>
                <button className="bg-[#2575FC] w-56 rounded-lg">
                  <i className='fab fa-google-play mx-4'></i>
                  Get it on Google Play
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className='text-lg my-4'>FOLLOW US ON SOCIAL MEDIA</div>
            <div className='leading-10 text-base flex justify-around font-normal'>            
                    <div className='cursor-pointer' style={{ color: 'blue' }}>
                        <a href="https://www.facebook.com/share/r/1Aq8Rv1KNr/" target='_blank'><i className='fab fa-facebook'></i></a>
                    </div>
                    <div className='cursor-pointer' style={{ color: 'dodgerblue' }}>
                        <a href="https://www.instagram.com/saq_technology_kadapa/profilecard/?igsh=d3IxMjB2N3ZsZXdj" target='_blank'><i className='fab fa-instagram'></i></a>
                    </div>                    
                    <div className='cursor-pointer' style={{ color: 'red' }}>
                        <a href="https://www.youtube.com/watch?v=_LgPYdTdwGQ" target="_blank" rel="noopener noreferrer">
                            <i className='fab fa-youtube'></i>
                        </a>
                    </div>
                    <div className='cursor-pointer' style={{ color: 'navy' }}>
                        <i className='fab fa-linkedin'></i>
                    </div>
                    <div className='cursor-pointer' style={{ color: 'black' }}>
                        <i className='fab fa-github'></i>
                    </div>
                    <div className='cursor-pointer' style={{ color: 'green' }}>
                        <i className='fab fa-google-play'></i>
                    </div>
                
            </div>
          </div>
        </div>
      </div>
      <div className='flex my-6 text-sm justify-between mx-4'>
        <div>&copy; 2024 SAQTECHNOLOGY. All Right Reserved.</div>
        <div>
          <ul className='flex'>
            <li className='mx-2'>Help</li>
            <li className='mx-2'>Privacy Policy</li>
            <li className='mx-2'>Cookie Policy</li>
            <li className='mx-2'>Terms Conditions</li>
            <li className='mx-2'>Service Terms of Use</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Footer