import React from 'react';

const images = {
    img1: require('../../images/expimg1.jpg'),
    imgd: require('../../images/user.jpg'),
    img4: require('../../images/expimg4.jpg'),
    img5: require('../../images/expimg5.jpg'),
    
   
};

const data1 = [
    { id: 1, name: 'Shaik Ameer Basha', course: 'SalesForce', img: images.img1 },
    { id: 2, name: 'Shaik Aashiq Ahamed', course: 'Azure', img: images.imgd },
    { id: 3, name: 'Shaik Mohammad Ghouse', course: 'SalesForce', img: images.imgd },
    { id: 4, name: 'Pathan Mazhar Ali Khan', course: 'Java Full Stack', img: images.img4 },
    { id: 5, name: 'Shaik Sohail', course: 'Digital Marketing', img: images.img5 },
    
];

const ExpertList = ({ experts = [] }) => {
    return (
        <div className="flex flex-wrap justify-center">
            {experts.map((item) => (
                <div key={item.id} className="w-64 h-64 my-4 mx-2 shadow-xl rounded-xl">
                    <div className="h-3/5 flex items-center justify-center">
                        <img
                            className="h-full object-contain"
                            src={item.img || '/path/to/default.jpg'}
                            alt="experts"
                        />
                    </div>
                    <div className="mx-3 h-2/5 flex flex-col justify-around py-3">
                        <div className="font-semibold text-sm">Name - <span style={{ color: 'rgb(14, 118, 168)' }}>{item.name}</span></div>
                        <div className="font-semibold text-sm">Trainer's - {item.course}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default function App() {
    return <ExpertList experts={data1} />;
}
