import React from "react";
import Header from "./components/Header/Header";
import Body from "./components/Body/Body";
import { createBrowserRouter, Outlet } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";
import Footer from "./components/Footer/Footer";
import CheatSheet from "./components/Dropdowns/Explore/CheatSheet";
import CodingProblems from "./components/Dropdowns/Explore/CodingProblems";
import FreeCourses from "./components/Dropdowns/Explore/FreeCourses";
import PremiumCourses from "./components/Dropdowns/Explore/PremiumCourses";
import Dashboard from "./components/Dashboard/Dashboard";
import Roadmap from "./components/Dropdowns/Explore/Roadmap";
import Blog from "./components/Body/Blog";
import Events from "./components/Body/Events";
import AboutUs from "./components/Body/AboutUs";
import ContactUs from "./components/Body/ContactUs";

const App = () => {

  return (
    <>
      <Header />
      <Outlet />
      <Footer/>
    </>
  );
}

export const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <Provider store={store}><App /></Provider>,
    children: [
      {
        path: "/",
        element: <Body />
      },
      {
        path: "cheat-sheet",
        element: <CheatSheet />
      },
      {
        path: "view-premium-courses",
        element: <PremiumCourses />
      },
      {
        path: "view-free-courses",
        element: <FreeCourses />
      },
      {
        path: "coding-problems",
        element: <CodingProblems />
      },
      {
        path: "roadmap",
        element: <Roadmap />
      },
      {
        path: "blog",
        element: <Blog />
      },
      {
        path: "events",
        element: <Events />
      },
      {
        path: "about_us",
        element: <AboutUs />
      },
      {
        path: "contact_us",
        element: <ContactUs />
      },
      
    ]
  }
]);