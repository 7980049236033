import React from 'react'
import { Link } from 'react-router-dom'

const TopHeader = () => {
    return (
        <div className='bg-[rgb(14_118_168)] h-14 text-sm font-semibold w-full'>
            <div className='flex flex-wrap justify-between items-center h-full mx-6'>
                <div className='flex justify-between w-1/2'>
                    <div className='flex' style={{color:'white'}}>
                        <div className='mx-1'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" />
                            </svg>
                        </div>
                        <div>+91-7093081073</div>
                    </div>
                    <div className='flex' style={{color:'white'}}>
                        <div className='mx-1'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                            </svg>
                        </div>
                        <div>support@saqtechnology.com</div>
                    </div>
                    {/* <div className='flex'>
                        <Link to="/admin-dashboard">Dashboard</Link>
                    </div> */}
                </div>
                <div className='h-6' style={{color:'white'}}>
                    <div>Don't miss Opening Offer: Up to 20% OFF</div>
                </div>
                <div className='flex justify-evenly w-1/6 h-6'>
                    <div className='cursor-pointer' style={{ color: 'blue' }}>
                        <a href="https://www.facebook.com/share/r/1Aq8Rv1KNr/" target='_blank'><i className='fab fa-facebook'></i></a>
                    </div>
                    <div className='cursor-pointer' style={{ color: 'dodgerblue' }}>
                        <a href="https://www.instagram.com/saq_technology_kadapa/profilecard/?igsh=d3IxMjB2N3ZsZXdj" target='_blank'><i className='fab fa-instagram'></i></a>
                    </div>                    
                    <div className='cursor-pointer' style={{ color: 'red' }}>
                        <a href="https://www.youtube.com/watch?v=_LgPYdTdwGQ" target="_blank" rel="noopener noreferrer">
                            <i className='fab fa-youtube'></i>
                        </a>
                    </div>
                    <div className='cursor-pointer' style={{ color: 'navy' }}>
                        <i className='fab fa-linkedin'></i>
                    </div>
                    <div className='cursor-pointer' style={{ color: 'black' }}>
                        <i className='fab fa-github'></i>
                    </div>
                    <div className='cursor-pointer' style={{ color: 'green' }}>
                        <i className='fab fa-google-play'></i>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default TopHeader