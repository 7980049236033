import React, { useEffect, useState } from 'react';

//import TraineeList from 'TraineeList';
import ExpertList from './ExpertList';
import {  useDispatch } from 'react-redux';
import { setEventsData, setLoader } from '../../Slices/AdminSlice';
//import { Button } from '@mui/material';

const Experts = () => {

    const [active, setActive] = useState({ first: true, second: false, third: false });
    //let events = useSelector(store => store.admin.eventsData);
    const dispatch = useDispatch();

    const handleClick = (tab) => {
        if (tab === "courses")
          setActive({ first: true, second: false, third: false });
        else if (tab === "design")
          setActive({ first: false, second: true, third: false });
        else
          setActive({ first: false, second: false, third: true });
      }
    

    return (
        <div className='bg-[#EEF2F6] my-16 py-12'>
            <div className='mx-4'>
                <div className='flex justify-between'>
                    <div className='flex flex-col'>
                        <span className='sm:text-3xl text-base mb-2 font-bold'>Our Expert's</span>
                    </div>
                    <div className='flex flex-wrap justify-around w-fit h-1/2'>
                        <button className="w-40 text-[#6440FB] font-semibold rounded-lg border-2 border-solid border-[#6440FB] py-3 hover:text-white hover:bg-[#6440FB]">View All</button>                       
                        
                    </div>
                </div>
                <div className='flex flex-wrap my-3'>
                    {/* {
                        events && events.length > 0 &&
                        events.map(event => {
                            return <TraineeList />
                        })
                    } */}
                    <ExpertList />
                </div>
            </div>
        </div>
    )
}

export default Experts