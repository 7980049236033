import React from 'react';

const images = {    
    img1: require('../../images/fsimg1.jpg'),
   
};

const data = [
    { id: 1, name: 'G.SHABAZ ALI KHAN', course: 'Full Stack Developer', img: images.img1 },
    
];

const TraineeList4 = ({ trainees = [] }) => {
    return (
        <div className="flex flex-wrap justify-center">
            {trainees.map((item) => (
                <div key={item.id} className="w-64 h-64 my-4 mx-2 shadow-xl rounded-xl">
                    <div className="h-3/5 flex items-center justify-center">
                        <img
                            className="h-full object-contain"
                            src={item.img || '/path/to/default.jpg'}
                            alt="Trainee"
                        />
                    </div>
                    <div className="mx-3 h-2/5 flex flex-col justify-around py-3">
                        <div className="font-semibold text-sm">Name - <span style={{ color: 'rgb(14, 118, 168)' }}>{item.name}</span></div>
                        <div className="font-semibold text-sm">Course Name - {item.course}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default function App() {
    return <TraineeList4 trainees={data} />;
}
