import React, { useEffect, useState } from 'react';

const ContactUs = () =>{
    return (
        <div className="contact-us-section bg-gray-50 py-12 px-6 md:px-20">
          {/* Heading */}
          <h1 className="text-4xl font-bold text-center text-blue-600 mb-8">Contact Us</h1>
    
          {/* Content */}
          <div className="flex flex-col md:flex-row justify-between items-center gap-8">
            {/* Left: Contact Information */}
            <div className="md:w-1/2 text-gray-700">
              <h2 className="text-2xl font-semibold mb-4">Get in Touch</h2>
              <p className="mb-4">
                We’d love to hear from you! Whether you have a question, feedback, or need support,
                feel free to contact us. We’re here to help.
              </p>
              <p className="mb-2">
                <strong>Phone:</strong> 91-7093081073
              </p>
              <p className="mb-2">
                <strong>Email:</strong> support@saqtechnology.com
              </p>
              <p>
                <strong>Address:</strong> SAQ Technology,Machupalli road,Shankarapuram,Kadapa - 516001.
                <br/><strong>LandMark:</strong> Vignan Degree College
              </p>
            </div>
    
            {/* Right: Contact Form */}
            <div className="md:w-1/2 bg-white p-6 rounded-lg shadow-lg">
              <form className="space-y-4">
                {/* Name */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="name">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                    placeholder="Your name"
                  />
                </div>
    
                {/* Email */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                    placeholder="Your email"
                  />
                </div>
    
                {/* Message */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="message">
                    Message
                  </label>
                  <textarea
                    id="message"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                    rows="4"
                    placeholder="Your message"
                  ></textarea>
                </div>
    
                {/* Submit Button */}
                <div>
                  <button
                    type="submit"
                    className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition-colors"
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
}

export default ContactUs;