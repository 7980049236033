import React from 'react';
import { categoriesData } from '../../utils/constants';
import Category from './Category';

const Categories = () => {
  return (
    <div className='my-16'>
      <div className='flex flex-col justify-center items-center my-7'>
        <span className='text-3xl my-1'><b>Our Most Popular Courses</b></span>
        <span className='text-[#7A7A7A] my-1'></span>
      </div>
      <div className='my-4 flex flex-wrap mx-4'>
        {
          categoriesData.map((category, idx) => {
            return <Category key={idx} category={category} />
          })
        }
      </div>
    </div>
  )
}

export default Categories