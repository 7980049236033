import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Course from '../../Body/Course';
import { setCoursesData, setLoader } from './../../../Slices/AdminSlice';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';



const PremiumCourses = () => {
  //let courses = useSelector(store => store.admin.coursesData);
  let loading = useSelector(store => store.admin.loading);

  const images = {  
    img1: require('../../../images/saleforce.jpg'),
    img2: require('../../../images/azure.jpg'),
    img3: require('../../../images/fullStack.jpg'),
    img4: require('../../../images/digital.jpg'),
    img5: require('../../../images/qclick.jpg'),
  }
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(setLoader());
  //   fetch("https://makfreelancer.com/myproject/hackerAlgo/api/Mobile_Services/courseList")
  //     .then(res => res.json())
  //     .then(data => dispatch(setCoursesData(data)))
  // }, [])
  let courses = [
    {courseId:1,courseTitle:'SalesForce',courseDesc:'',courseCategory:'1',courseStatus:'1',courseImage:images.img1},
    {courseId:2,courseTitle:'Azure',courseDesc:'',courseCategory:'1',courseStatus:'1',courseImage:images.img2},
    {courseId:3,courseTitle:'Full Stack Developer',courseDesc:'',courseCategory:'1',courseStatus:'1',courseImage: images.img3},
    {courseId:4,courseTitle:'Qlick Sense',courseDesc:'',courseCategory:'2',courseStatus:'1',courseImage:images.img5},
    {courseId:5,courseTitle:'Digital Marketing',courseDesc:'',courseCategory:'1',courseStatus:'1',courseImage:images.img4},
  ];
  if (loading)
    return <Box className=" fixed z-50 mx-96 my-36">
      <CircularProgress />
    </Box>

  return (
    <div className='my-16 mx-4'>
      <div className='flex flex-wrap my-4'>
        {
          courses && courses.length > 0 &&
          courses.map(course => {
            return course.courseCategory === "1" && <Course course={course} key={course.courseId} />
          })
        }
      </div>
    </div>
  )
}

export default PremiumCourses