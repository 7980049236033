import React from 'react';

const images = {
    img1: require('../../images/ohimg1.jpg'),
    imgd: require('../../images/user.jpg'),
    img2: require('../../images/ohimg2.jpg'),
    img3: require('../../images/ohimg3.jpg'),
    img4: require('../../images/ohimg4.jpg'),  
};

const data1 = [
    { id: 1, name: 'Shaik Jeelan', company: 'Accenture', img: images.img1 },
    { id: 2, name: 'Shaik Sohail', company: 'Hexaware Technologies', img: images.img2 },
    { id: 3, name: 'Arjun Singh Walia', company: 'Alpha Deta(Dubai)', img: images.img3 },
    { id: 4, name: 'P Saif Ali Khan', company: 'Prudent Global Tech', img: images.img4 },
    
    
];

const OurHeroList = ({ ourHero = [] }) => {
    return (
        <div className="flex flex-wrap justify-center">
            {ourHero.map((item) => (
                <div key={item.id} className="w-64 h-64 my-4 mx-2 shadow-xl rounded-xl">
                    <div className="h-3/5 flex items-center justify-center">
                        <img
                            className="h-full object-contain"
                            src={item.img || '/path/to/default.jpg'}
                            alt="experts"
                        />
                    </div>
                    <div className="mx-3 h-2/5 flex flex-col justify-around py-3">
                        <div className="font-semibold text-sm">Name - <span style={{ color: 'rgb(14, 118, 168)' }}>{item.name}</span></div>
                        <div className="font-semibold text-sm">Company - {item.company}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default function App() {
    return <OurHeroList ourHero={data1} />;
}
