import React, { useEffect, useState } from 'react';
import Event from './Event';
import { useSelector, useDispatch } from 'react-redux';
import { setEventsData, setLoader } from '../../Slices/AdminSlice';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Events = () => {

    let events = useSelector(store => store.admin.eventsData);
    const dispatch = useDispatch();
    const videoSrc = "https://www.youtube.com/embed/_LgPYdTdwGQ?autoplay=1&mute=1&loop=1&playlist=_LgPYdTdwGQ";

    return (
        <div className='bg-[#EEF2F6] my-16 py-12'>
            <div className='mx-4'>
                <div className='flex justify-between'>
                    <div className='flex flex-col'>
                        <span className='sm:text-3xl text-base mb-2 font-bold'>Upcoming Events</span>
                    </div>
                    <div>
                    <Link to="/events"><button className="w-40 text-[#6440FB] font-semibold rounded-lg border-2 border-solid border-[#6440FB] py-3 hover:text-white hover:bg-[#6440FB]">View All Events</button></Link>
                    </div>
                </div>
                {/* <div className='flex flex-wrap my-4'>
                    {
                        events && events.length > 0 &&
                        events.map(event => {
                            return <Event event={event} key={event.eventId} />
                        })
                    }
                </div> */}
                 <div className='h-full w-full'>
                {/* <img className='object-cover h-full w-full' src={a} alt="Your SVG" /> */}
                <iframe
                    width="500"
                    height="360"
                    src={videoSrc}
                    title="SAQ Technologies"
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                ></iframe>

            </div>
            </div>
        </div>
    )
}

export default Events