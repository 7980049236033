import React, { useEffect, useState } from 'react';

const AboutUs = () =>{
    return (
        <div className="about-us-section flex flex-col md:flex-row items-center justify-center bg-gray-50 p-10">
          {/* Left Content */}
          <div className="md:w-1/2 text-center md:text-left mb-6 md:mb-0">
            <h1 className="text-4xl font-bold text-blue-600 mb-4">About Us</h1>
            <p className="text-gray-700 text-lg mb-4">
              Welcome to our company! We are passionate about providing innovative solutions and
              exceptional service. Our team works tirelessly to ensure customer satisfaction and drive
              success in all our projects.
            </p>
            <p className="text-gray-700 text-lg">
              With a mission to empower individuals and businesses, we strive to make a meaningful
              impact through our work.
            </p>
          </div>
    
          {/* Right Image */}
          <div className="md:w-1/2 flex justify-center">
            <img
              src="https://via.placeholder.com/400x300"
              alt="About Us"
              className="rounded-lg shadow-lg"
            />
          </div>
        </div>
      );
}

export default AboutUs;