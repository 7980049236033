import React from 'react';

const images = {
    img1: require('../../images/azureimg1.jpg'),
    img2: require('../../images/azureimg2.jpg'),
    img3: require('../../images/azureimg3.jpg'),
    img4: require('../../images/azureimg4.jpg'),
    img5: require('../../images/azureimg5.jpg'),
   
    img: require('../../images/user.jpg'),
   
};

const data = [
    { id: 1, name: 'K.SANTOSH KUMAR', course: 'Azure', img: images.img },
    { id: 2, name: 'P SIJJAD ALI KHAN', course: 'Azure', img: images.img1 },
    { id: 3, name: 'SHAIK MD MAAZ', course: 'Azure', img: images.img2 },
    { id: 4, name: 'SHAIK WAHID BASHA', course: 'Azure', img: images.img5 },
    { id: 5, name: 'SHAIK WAZID', course: 'Azure', img: images.img4 },
    { id: 6, name: 'SYED TAUQEER NISHAD', course: 'Azure', img: images.img3 }
];

const TraineeList3 = ({ trainees = [] }) => {
    return (
        <div className="flex flex-wrap justify-center">
            {trainees.map((item) => (
                <div key={item.id} className="w-64 h-64 my-4 mx-2 shadow-xl rounded-xl">
                    <div className="h-3/5 flex items-center justify-center">
                        <img
                            className="h-full object-contain"
                            src={item.img || '/path/to/default.jpg'}
                            alt="Trainee"
                        />
                    </div>
                    <div className="mx-3 h-2/5 flex flex-col justify-around py-3">
                        <div className="font-semibold text-sm">Name - <span style={{ color: 'rgb(14, 118, 168)' }}>{item.name}</span></div>
                        <div className="font-semibold text-sm">Course Name - {item.course}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default function App() {
    return <TraineeList3 trainees={data} />;
}
