import React from 'react';

const images = {
    img1: require('../../images/sfimg1.jpg'),
    img2: require('../../images/sfimg2.jpg'),
    img3: require('../../images/sfimg3.jpg'),
    img4: require('../../images/sfimg4.jpg'),
    img5: require('../../images/sfimg5.jpg'),
    img7: require('../../images/sfimg7.jpg'),
    img8: require('../../images/sfimg12.jpg'),
    imgd: require('../../images/user.jpg'),
   
};

const data = [
    { id: 1, name: 'AQUEEB JUNAID KHAN MOHAMMED', course: 'SalesForce', img: images.img1 },
    { id: 2, name: 'AVULA VENKATA SUBBARAYUDU', course: 'SalesForce', img: images.img2 },
    { id: 3, name: 'KATIKA RIZWAN AHAMED', course: 'SalesForce', img: images.img3 },
    { id: 4, name: 'NUTHALAPATI SAI NATH', course: 'SalesForce', img: images.imgd },
    { id: 5, name: 'SHAIK MANSOOR ALI', course: 'SalesForce', img: images.img5 },
    { id: 6, name: 'SHAIK MOULALI', course: 'SalesForce', img: images.imgd },
    { id: 7, name: 'SHAIK VAFA', course: 'SalesForce', img: images.img7 },
    { id: 8, name: 'SHAIK YASMEEN', course: 'SalesForce', img: images.img8 },
];

const TraineeList2 = ({ trainees = [] }) => {
    return (
        <div className="flex flex-wrap justify-center">
            {trainees.map((item) => (
                <div key={item.id} className="w-64 h-64 my-4 mx-2 shadow-xl rounded-xl">
                    <div className="h-3/5 flex items-center justify-center">
                        <img
                            className="h-full object-contain"
                            src={item.img || '/path/to/default.jpg'}
                            alt="Trainee"
                        />
                    </div>
                    <div className="mx-3 h-2/5 flex flex-col justify-around py-3">
                        <div className="font-semibold text-sm">Name - <span style={{ color: 'rgb(14, 118, 168)' }}>{item.name}</span></div>
                        <div className="font-semibold text-sm">Course Name - {item.course}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default function App() {
    return <TraineeList2 trainees={data} />;
}
