import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setEventsData, setLoader } from "../../Slices/AdminSlice";
import TraineeList from "./TraineeList";
import TraineeList2 from "./TraineeList2";
import TraineeList3 from "./TraineeList3";
import TraineeList4 from "./TraineeList4";

const Trainee = () => {
  const [active, setActive] = useState("SaleForcemorning"); // Hold the active tab key as a string
  const dispatch = useDispatch();

  const handleClick = (tab) => {
    setActive(tab); // Update the active tab directly by key
  };

  return (
    <div className="bg-[#EEF2F6] my-16 py-12">
      <div className="mx-4">
        <div className="flex justify-between">
          <div className="flex flex-col">
            <span className="sm:text-3xl text-base mb-2 font-bold">
              Our Trainee's
            </span>
          </div>
          <div className="flex flex-wrap justify-around w-fit h-1/2">
            {/* Tab Buttons */}
            <div
              onClick={() => handleClick("SaleForcemorning")}
              className={`cursor-pointer rounded-full px-4 py-2 flex justify-center items-center ${
                active === "SaleForcemorning" ? "bg-[rgba(14,118,168,0.07)]" : "hover:bg-[rgba(14,118,168,0.07)]"
              }`}
            >
              <span
                className={`font-semibold ${
                  active === "SaleForcemorning" ? "text-[#0e76a8]" : "text-[#4f547b] hover:text-[#0e76a8]"
                }`}
              >
                SalesForce Morning Batch
              </span>
            </div>
            <div
              onClick={() => handleClick("SaleForceevening")}
              className={`cursor-pointer rounded-full px-4 py-2 flex justify-center items-center ${
                active === "SaleForceevening" ? "bg-[rgba(14,118,168,0.07)]" : "hover:bg-[rgba(14,118,168,0.07)]"
              }`}
            >
              <span
                className={`font-semibold ${
                  active === "SaleForceevening" ? "text-[#0e76a8]" : "text-[#4f547b] hover:text-[#0e76a8]"
                }`}
              >
                SalesForce Evening Batch
              </span>
            </div>
            <div
              onClick={() => handleClick("azure")}
              className={`cursor-pointer rounded-full px-4 py-2 flex justify-center items-center ${
                active === "azure" ? "bg-[rgba(14,118,168,0.07)]" : "hover:bg-[rgba(14,118,168,0.07)]"
              }`}
            >
              <span
                className={`font-semibold ${
                  active === "azure" ? "text-[#0e76a8]" : "text-[#4f547b] hover:text-[#0e76a8]"
                }`}
              >
                Azure
              </span>
            </div>
            <div
              onClick={() => handleClick("java")}
              className={`cursor-pointer rounded-full px-4 py-2 flex justify-center items-center ${
                active === "java" ? "bg-[rgba(14,118,168,0.07)]" : "hover:bg-[rgba(14,118,168,0.07)]"
              }`}
            >
              <span
                className={`font-semibold ${
                  active === "java" ? "text-[#0e76a8]" : "text-[#4f547b] hover:text-[#0e76a8]"
                }`}
              >
                Java Full Stack
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap my-3">
          {/* Render Tab-Specific Content */}
          {active === "SaleForcemorning" && <TraineeList title="SalesForce Morning Batch" />}
          {active === "SaleForceevening" && <TraineeList2 title="SalesForce Evening Batch" />}
          {active === "azure" && <TraineeList3 title="Azure" />}
          {active === "java" && <TraineeList4 title="Java Full Stack" />}
        </div>
      </div>
    </div>
  );
};

export default Trainee;
