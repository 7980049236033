import React from 'react';
import Banner from './Banner';
import Categories from './Categories';
import Courses from './Courses';
import Events from './Events';
import Trainee from './trainee';
import OurHero from './OurHero';
import Experts from './Experts';

const Body = () => {
  return (
    <div>
        <Banner/>
        <Categories/>
        {/* <Courses/> */}
        <Trainee/>
        <OurHero />
        <Experts />        
        <Events/>
    </div>
  )
}

export default Body