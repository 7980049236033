import React,{ useState, useEffect } from 'react';
//import BannerImg from './BannerImg';

import img1 from '../../images/dummy.jpg';
import img2 from '../../images/dummy2.jpg';
import img3 from '../../images/dummy3.jpg';

const Banner = () => {
   const slides = [
    { id: 1, image: img1, title: 'SAQ TECHNOLOGY', subtitle: 'Welcome to SAQ TECHNOLOGY' },
    { id: 2, image: img2, title: '', subtitle: '' },
    { id: 3, image: img3, title: '', subtitle: '' },   
  ];
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % slides.length);
      }, 3000); // Slide every 3 seconds
      return () => clearInterval(interval);
    }, [slides.length]);
  
    return (
      <div style={{ position: 'relative', overflow: 'hidden', width: '100%', height: '350px' }}>
        <div
          style={{
            display: 'flex',
            transform: `translateX(-${currentIndex * 100}%)`,
            transition: 'transform 0.5s ease-in-out',
          }}
        >
          {slides.map(slide => (
            <div
              key={slide.id}
              style={{                
                minWidth: '100%',
                backgroundImage: `url(${slide.image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '350px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                textShadow: '0 2px 4px rgba(0, 0, 0, 0.7)',
              }}
            >
              <h2>{slide.title}</h2>
              <p>{slide.subtitle}</p>
            </div>
          ))}
        </div>
      </div>
    );

}

export default Banner;