import React from 'react';

const images = {
    // img1: require('../../images/sfimg1.jpg'),
     img2: require('../../images/sfimg9.jpg'),
     img6: require('../../images/sfimg11.jpg'),
     img7: require('../../images/sfimg10.jpg'),
    // img8: require('../../images/sfimg8.jpg'),
    img: require('../../images/user.jpg'),
    img10: require('../../images/sfimg4.jpg'),
    img11: require('../../images/sfimg13.jpg'),
    img12: require('../../images/sfimg14.jpg'),
   
};

const data = [   
  { id: 1, name: 'ALAMURU SHAKEER HUSSAIN', course: 'SalesForce', img: images.img },
  { id: 2, name: 'AYESHA ABDUL AZEEZ', course: 'SalesForce', img: images.img2 },
  { id: 3, name: 'BITNI ROSHNA NUSHEEN', course: 'SalesForce', img: images.img },
  { id: 4, name: 'SHAIK AMEEN BASHA', course: 'SalesForce', img: images.img },
  { id: 5, name: 'SHAIK NAYAB RASOOL', course: 'SalesForce', img: images.img },
  { id: 6, name: 'SHARMILA DEVI.G.R', course: 'SalesForce', img: images.img6 },
  { id: 7, name: 'SYED ANWAR BASHA', course: 'SalesForce', img: images.img7 },
  { id: 8, name: 'SYED JANI BASHA', course: 'SalesForce', img: images.img },
  { id: 9, name: 'SYED KHUSHBU', course: 'SalesForce', img: images.img11 },
  { id: 10, name: 'VEMMALA RAJU SREE SAI', course: 'SalesForce', img: images.img10 },
  { id: 11, name: 'YETURE MAHESH BABU', course: 'SalesForce', img: images.img },
  { id: 12, name: 'SHAIK SAABIR HUSSAIN', course: 'SalesForce', img: images.img },
  { id: 13, name: 'SYED ATHAR BASHA', course: 'SalesForce', img: images.img },
  { id: 14, name: 'Shaik Ameen', course: 'SalesForce', img: images.img12 }
];

const TraineeList = ({ trainees = [] }) => {
    return (
        <div className="flex flex-wrap justify-center">
            {trainees.map((item) => (
                <div key={item.id} className="w-64 h-64 my-4 mx-2 shadow-xl rounded-xl">
                    <div className="h-3/5 flex items-center justify-center">
                        <img
                            className="h-full object-contain"
                            src={item.img || '/path/to/default.jpg'}
                            alt="Trainee"
                        />
                    </div>
                    <div className="mx-3 h-2/5 flex flex-col justify-around py-3">
                        <div className="font-semibold text-sm">Name - <span style={{ color: 'rgb(14, 118, 168)' }}>{item.name}</span></div>
                        <div className="font-semibold text-sm">Course Name - {item.course}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default function App() {
    return <TraineeList trainees={data} />;
}
