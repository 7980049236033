import React from 'react'
//const img = require('../../images/fullStack.jpg');
const Course = ({ course }) => {
    return (
        <div className='w-80 h-96 my-4 mx-10 shadow-xl rounded-xl'>
            <div className='h-3/5'>
                <img className='h-full' src={course.courseImage}></img>
            </div>
            <div className='mx-3 h-2/5 flex flex-col justify-around py-3'>
                <div className='font-semibold text-lg'>Course Name - {course.courseTitle}</div>
                <div className='font-semibold text-sm'>Course Description - {course.courseDesc}</div>
                <div className='flex justify-between font-semibold text-sm'>
                    <div className={(course.courseStatus === "1" ? 'h-6 w-fit p-0.25 px-2 text-center rounded-lg bg-green-100 text-green-900' : "h-6 w-fit p-0.25 px-2 text-center rounded-lg bg-red-100 text-red-900")}>{course.courseStatus === "1" ? "Active" : "Inactive"}</div>
                    <div className={(course.courseCategory === "1" ? 'h-6 w-fit p-0.25 px-2 text-center rounded-lg bg-green-100 text-green-900' : "h-6 w-fit p-0.25 px-2 text-center rounded-lg bg-red-100 text-red-900")}>{course.courseCategory === "1" ? "Premium" : "Free"}</div>
                </div>
            </div>
        </div>
    )
}

export default Course