import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Course from '../../Body/Course';
import { setCoursesData, setLoader } from './../../../Slices/AdminSlice';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const FreeCourses = () => {
  //let courses = useSelector(store => store.admin.coursesData);
  let loading = useSelector(store => store.admin.loading);
  const images = {  
    img4: require('../../../images/qclick.jpg'),
  }
  const dispatch = useDispatch();
  let courses = [
    {courseId:1,courseTitle:'SalesForce',courseDesc:'',courseCategory:'1',courseStatus:'1',courseImage:images.img1},
    {courseId:2,courseTitle:'Azure',courseDesc:'',courseCategory:'1',courseStatus:'1',courseImage:images.img2},
    {courseId:3,courseTitle:'Full Stack Developer',courseDesc:'',courseCategory:'1',courseStatus:'1',courseImage:images.img3},
    {courseId:4,courseTitle:'Qlick Sense',courseDesc:'',courseCategory:'0',courseStatus:'1',courseImage:images.img4},
    {courseId:5,courseTitle:'Digital Marketing',courseDesc:'',courseCategory:'1',courseStatus:'1',courseImage:images.img5},
  ];

  if (loading)
    return <Box className=" fixed z-50 mx-96 my-36">
      <CircularProgress />
    </Box>

  return (
    <div className='my-16 mx-4'>
      <div className='flex flex-wrap my-4'>
        {
          courses && courses.length > 0 &&
          courses.map(course => {
            return course.courseCategory === "0" && <Course course={course} key={course.courseId} />
          })
        }
      </div>
    </div>
  )
}

export default FreeCourses